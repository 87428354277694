import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import {View, Text} from 'react-native';

import EtherButton from './EtherButton';
import {useTheme} from '../context/ThemeContext';
import ResetCart from '../screens/cart/ResetCart';
import {HEADER_HEIGHT_KIOSK} from '../utils/common/constants';

export default function KioskHeader() {
  const {style} = useTheme(getThemedStyles);
  const storeName = localStorage.getItem('storeName');
  const eventName = localStorage.getItem('eventName');

  const isKiosk = window.location.pathname.includes('/kiosk');
  const isCart = window.location.pathname.includes('/cart');

  return (
    <View style={style.header}>
      <View style={style.headerBackButtonGroup}>
        {isKiosk ? null : (
          <EtherButton
            style={style.headerBackButton}
            icon={faArrowLeft}
            iconSize={35}
            iconStyle={style.headerArrow}
            onPress={() => window.history.go(-1)}
          />
        )}
        <Text style={style.headerFont}>{eventName || ''}</Text>
      </View>
      <View>
        <Text style={style.headerFont}>{storeName || ''}</Text>
      </View>
      {isKiosk || isCart ? <ResetCart /> : null}
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  header: {
    width: '100%',
    height: HEADER_HEIGHT_KIOSK,
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    backgroundColor: theme.SECOND,
    borderBottomWidth: 3,
    borderColor: theme.FIRST,
  },
  headerArrow: {
    position: 'absolute',
    color: theme.LIGHT,
    marginLeft: 0,
    marginTop: 4,
  },
  headerBackButton: {
    borderWidth: 0,
  },
  headerBackButtonGroup: {
    alignSelf: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
    position: 'absolute',
    marginLeft: 20,
    height: 45,
  },
  headerFont: {
    fontFamily: 'NotoSans_Bold',
    textTransform: 'uppercase',
    fontSize: fontSize.subheader,
    color: theme.LIGHT,
  },
  resetCartButton: {
    position: 'absolute',
    width: 150,
    height: 40,
    backgroundColor: theme.SECOND,
    borderColor: theme.FIRST,
    borderRadius: 5,
    borderWidth: 3,
    alignItems: 'center',
    justifyContent: 'center',
    right: 15,
  },
  resetCartButtonText: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.subheader,
    color: theme.LIGHT,
  },
});
