import {useReducer} from 'react';
import toast from 'react-hot-toast';

const blueprint = {
  events: null,
  currentEvent: null,
  packs: null,
  currentPack: null,
  assets: null,
  selectedAssets: [],
  currentAsset: null,
  packPrice: null,
  socket: null,
};

function assetsReducer(state, action) {
  console.log('Dispatching action: ', action);

  switch (action.type) {
    //------------- Socket -------------//
    case 'socket.connect': {
      const {socket} = action.value;
      return {...state, socket};
    }
    case 'socket.message': {
      const {wsType, payload} = action.value;

      switch (wsType) {
        case 'asset.update': {
          const {asset} = payload;

          // It's not our pack, don't update
          if (asset?.packId !== state.currentPack?._id) {
            return state;
          }

          const assetIdx = state.assets.findIndex((a) => a._id === asset._id);
          if (assetIdx > -1) {
            state.assets[assetIdx] = asset;
          } else {
            state.assets.push(asset);
          }
          return {...state};
        }
        default: {
          console.error(
            'Unknown socket message type: ',
            wsType,
            '\nPayload: ',
            payload,
          );
          return state;
        }
      }
    }

    //------------- Events -------------//
    case 'event.create': {
      const {event} = action.value;
      state.events.push(event);
      return {...state};
    }
    case 'event.select': {
      const {event: eventName} = action.value;
      const event = state.events.find((e) => e.name === eventName);
      if (!event) {
        toast.error(`Tried to select nonexistent event: ${eventName}`);
        return state;
      }
      return {
        ...state,
        currentEvent: event,
        packs: [],
        currentPack: null,
        assets: [],
        currentAsset: null,
      };
    }
    case 'event.rename': {
      const {event, oldName} = action.value;
      state.events = state.events.map((e) => (e.name === oldName ? event : e));
      return {...state};
    }
    case 'event.delete': {
      const {event} = action.value;
      state.events = state.events.filter((e) => e.name !== event.name);
      return {...state};
    }

    case 'event.active': {
      const {eventId, active} = action.value;
      const event = state.events.find((e) => e._id === eventId);
      if (!event) {
        toast.error(`Tried to set nonexistent eventId as active: ${eventId}`);
        return state;
      }
      event.active = active;
      return {...state};
    }

    case 'event.add.access_code': {
      const {eventId, accessCode} = action.value;
      const eventIdx = state.events.findIndex((e) => e._id === eventId);
      if (eventIdx > -1) state.events[eventIdx].accessCode = accessCode;
      return {...state};
    }

    case 'event.remove.access_code': {
      const {eventId} = action.value;
      const eventIdx = state.events.findIndex((e) => e._id === eventId);
      if (eventIdx > -1) state.events[eventIdx].accessCode = null;
      return {...state};
    }

    case 'event.flyer.upload': {
      const {eventId, flyer} = action.value;
      const eventIdx = state.events.findIndex((e) => e._id === eventId);
      if (eventIdx > -1) {
        state.events[eventIdx].flyer = flyer;
      }
      return {...state};
    }
    case 'event.flyer.remove': {
      const {eventId} = action.value;
      const eventIdx = state.events.findIndex((e) => e._id === eventId);
      state.events[eventIdx].flyer = null;
      return {...state};
    }

    case 'events.load': {
      const {events, initialEvent} = action.value;

      // Use remembered event
      const initialEventData = events?.find((e) => e.name === initialEvent);
      if (initialEventData) {
        state.currentEvent = initialEventData;
      }
      // Otherwise auto-select first event
      else if (events?.length > 0) {
        state.currentEvent = events[0];
      }

      return {
        ...state,
        events,
      };
    }

    //------------- Packs -------------//
    case 'pack.create': {
      const {pack} = action.value;
      state.packs.push(pack);
      if (!state.currentPack) {
        state.currentPack = pack;
      }
      return {...state};
    }
    case 'pack.load': {
      const {pack} = action.value;
      return {
        ...state,
        currentPack: pack,
        packPrice: pack.price,
        assets: pack.assets,
      };
    }
    case 'pack.rename': {
      const {pack: renamedPack} = action.value;
      state.packs = state.packs.map((p) =>
        p._id === renamedPack._id ? renamedPack : p,
      );
      return {
        ...state,
        currentPack: renamedPack,
        packPrice: renamedPack.price,
        assets: renamedPack.assets,
      };
    }
    case 'pack.delete': {
      const {packName} = action.value;
      state.packs = state.packs.filter((e) => e.name !== packName);
      if (state.packs.length > 0) {
        state.currentPack = state.packs[0];
      } else {
        state.currentPack = null;
      }
      state.assets = [];
      return {...state};
    }
    case 'pack.active': {
      const {packId, active} = action.value;
      const pack = state.packs.find((p) => p._id === packId);
      if (!pack) {
        toast.error(`Tried to set nonexistent packId as active: ${packId}`);
        return state;
      }
      pack.active = active;
      if (packId === state.currentPack?._id) {
        state.currentPack.active = active;
      }
      return {...state};
    }

    case 'packs.load': {
      const {packs, initialPack} = action.value;

      // Use remembered pack
      const initialPackData = packs?.find((p) => p.name === initialPack);
      if (initialPackData) {
        state.currentPack = initialPackData;
      }
      // Otherwise auto-select first pack
      else if (packs?.length > 0) {
        state.currentPack = packs[0];
      } else if (packs?.length === 0) {
        state.currentPack = null;
        state.assets = [];
        state.currentAsset = null;
      }

      return {
        ...state,
        packs,
      };
    }

    //------------- Assets -------------//
    case 'asset.rename': {
      const {asset, oldName} = action.value;
      state.assets = state.assets.map((a) => (a.name === oldName ? asset : a));
      return {...state};
    }

    case 'assets.create': {
      const {assets} = action.value;
      state.assets = state.assets.concat(assets);
      return {...state};
    }

    default: {
      console.log('Unhandled action', state, action);
    }
  }
}

export function useAssetsReducer() {
  return useReducer(assetsReducer, blueprint);
}

export default assetsReducer;
