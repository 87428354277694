import {faFolder, faFolderOpen} from '@fortawesome/free-solid-svg-icons';
import {
  bool,
  arrayOf,
  array,
  func,
  string,
  object,
  shape,
  oneOfType,
  number,
} from 'prop-types';
import React, {useState} from 'react';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton';
import {View, FlatList, Text, TouchableOpacity} from 'react-native';

import FaIcon from './FaIcon';
import Search from './Search';
import {useTheme} from '../context/ThemeContext';
import {ellipsify} from '../utils/common/funcs';

const loadingContent = [{name: 1}, {name: 2}, {name: 3}, {name: 4}];

function SkeletonListButton() {
  const {style, values} = useTheme(getThemedStyles);

  return (
    <View style={style.listButton}>
      <SkeletonTheme
        baseColor={values.DISABLEDBORDER}
        highlightColor={values.DISABLED}
        width={180}
      >
        <Skeleton circle width={32} height={32} />
        <Skeleton
          style={{marginLeft: '12px', marginTop: '10px', marginBottom: '10px'}}
          width="200px"
          height={19}
        />
      </SkeletonTheme>
    </View>
  );
}

function ListButton({title, onPress, style: overrides, active}) {
  const {style, values} = useTheme(getThemedStyles);

  return (
    <TouchableOpacity
      style={[
        style.listButton,
        active ? style.listButtonActive : null,
        overrides,
      ]}
      title={title}
      onPress={onPress}
    >
      <FaIcon
        icon={active ? faFolderOpen : faFolder}
        color={active ? values.LIGHT : values.DARK}
        size={30}
      />
      <Text style={[style.buttonText, active ? style.buttonTextActive : null]}>
        {ellipsify(title, 30)}
      </Text>
    </TouchableOpacity>
  );
}

DirList.propTypes = {
  dirs: arrayOf(shape({name: string, path: string})),
  selected: string,
  onPress: func,
  style: oneOfType([object, number]),
  searchStyle: oneOfType([object, array, number]),
  refreshing: bool,
};
export default function DirList({
  dirs,
  selected,
  onPress = () => {},
  style: overrides,
  searchStyle: searchBarOverrides,
  listStyle: listOverrides,
  msgHeader,
  msgBody,
  renderItem = ListButton,
  loaded = true,
}) {
  const ListComponent = renderItem;
  const {style} = useTheme(getThemedStyles);
  const [filter, setFilter] = useState('');

  let displayContent = dirs;
  if (filter) {
    displayContent = dirs.filter((item) => {
      const itemNameLower = item.name.toLowerCase();
      const filterLower = filter.toLowerCase();
      return itemNameLower.includes(filterLower);
    });
  }

  function changeDir(newDir) {
    setFilter('');
    onPress(newDir);
  }

  return (
    <View style={[style.container, overrides]}>
      <Search
        onChangeText={(text) => setFilter(text)}
        focusStyle={searchBarOverrides}
        textboxStyle={searchBarOverrides}
        style={style.searchBar}
        placeholder="Search"
      />
      <FlatList
        style={[style.list, listOverrides]}
        data={loaded ? displayContent : loadingContent}
        keyboardShouldPersistTaps="always"
        renderItem={({item, index}) =>
          loaded ? (
            <ListComponent
              active={item.name === selected}
              title={item.name}
              onPress={() => changeDir(item)}
              style={index > 0 ? style.bumpDown : null}
            />
          ) : (
            <SkeletonListButton />
          )
        }
        keyExtractor={(item) => item.name}
        ListEmptyComponent={
          <>
            <Text style={style.noDirsText}>{msgHeader}</Text>
            <Text style={style.noDirsSubtext}>{msgBody}</Text>
          </>
        }
      />
      {!loaded ? <View style={style.mask} /> : null}
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  bumpDown: {
    marginTop: 10,
  },
  buttonText: {
    color: theme.DARK,
    marginLeft: 10,
    fontFamily: 'NotoSans_Bold',
  },
  buttonTextActive: {
    color: theme.LIGHT,
  },
  container: {
    flex: 1,
    backgroundColor: theme.BGFIRST,
    width: '100%',
  },
  list: {
    maxHeight: '100vh',
    overflowY: 'auto',
  },
  listButton: {
    padding: 10,
    backgroundColor: theme.BGFIRST,
    flexDirection: 'row',
    alignItems: 'center',
  },
  listButtonActive: {
    backgroundColor: theme.SECOND,
    color: theme.LIGHT,
    shadowColor: theme.DARK,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 2,
  },
  mask: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: 300,
    background: `linear-gradient(to bottom, ${theme.BGFIRST}00 0%, ${theme.BGFIRST}FF 100%)`,
  },
  noDirsSubtext: {
    fontFamily: 'NotoSans_Regular',
    fontSize: fontSize.tiny,
    alignSelf: 'center',
  },
  noDirsText: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.body,
    alignSelf: 'center',
    marginTop: 40,
    marginBottom: 20,
  },
  refreshText: {
    fontFamily: 'NotoSans_Regular',
    fontSize: fontSize.legal,
  },
  searchBar: {
    backgroundColor: theme.BGFIRST,
  },
  separator: {
    borderBottomColor: theme.FIRST,
    borderBottomWidth: 1,
    width: '10%',
    marginBottom: 3,
  },
});
