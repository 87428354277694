import {
  faAngleLeft,
  faAngleRight,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import {Text, View, Image, Pressable} from 'react-native';
import ImageViewer from 'react-native-image-zoom-viewer';

import EtherButton from './EtherButton';
import FaIcon from '../components/FaIcon';
import {useTheme} from '../context/ThemeContext';
import {conditionalS} from '../utils/common/funcs';

function RenderIndicator({style, values, onClose, onPressLeft, onPressRight}) {
  return (i, allSize) => (
    <View style={style.controls}>
      <EtherButton
        basic
        style={style.leftArrow}
        icon={faAngleLeft}
        iconColor={values.LIGHT}
        iconSize={48}
        onPress={onPressLeft}
        testID="viewer-prev"
      />
      <EtherButton
        basic
        style={style.rightArrow}
        icon={faAngleRight}
        iconColor={values.LIGHT}
        iconSize={48}
        onPress={onPressRight}
        testID="viewer-next"
      />
      <Pressable style={style.close} onPress={onClose} testID="viewer-close">
        <FaIcon icon={faTimes} size={40} color={values.LIGHT} />
      </Pressable>
      <Text style={style.counter} testID="viewer-counter">
        {i} / {allSize}
      </Text>
    </View>
  );
}

export default function ImageViewerComponent({
  onClose,
  assets,
  currentIndex,
  setIndex,
  videoWatermarkSrc,
}) {
  const {style, values} = useTheme(getThemedStyles);

  function handlePressLeft() {
    setIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : assets.length - 1,
    );
  }

  function handlePressRight() {
    setIndex((prevIndex) =>
      prevIndex < assets.length - 1 ? prevIndex + 1 : 0,
    );
  }

  return (
    <View
      style={{
        position: 'absolute',
        flex: 1,
        width: '100%',
        height: '100%',
      }}
    >
      <ImageViewer
        saveToLocalByLongPress={false}
        renderIndicator={RenderIndicator({
          style,
          values,
          onClose,
          onPressLeft: handlePressLeft,
          onPressRight: handlePressRight,
        })}
        style={style.imageViewer}
        imageUrls={assets.map(({preview, thumb, mime, width, height}) => {
          const url = mime.includes('image') ? preview.url : thumb.url;
          return {
            url,
            width,
            height,
            // Scale video preview thumbs to fit video size
            ...(mime.includes('video') ? {width: '100%', height: '100%'} : {}),
          };
        })}
        index={currentIndex}
        renderImage={({style: renderImageStyle, ...rest}) => {
          const asset = assets[currentIndex];

          if (asset?.mime?.includes('video')) {
            return (
              <WatermarkedVideo
                asset={asset}
                watermarkSrc={videoWatermarkSrc}
                {...rest}
              />
            );
          } else {
            return (
              <Image style={renderImageStyle} testID="viewer-image" {...rest} />
            );
          }
        }}
        enableSwipeDown
        onSwipeDown={onClose}
        onChange={setIndex}
      />
    </View>
  );
}

function WatermarkedVideo({asset, watermarkSrc, ...rest}) {
  const {style} = useTheme(getThemedStyles);

  const duration = Number(asset?.duration) || null;
  let durationStr = 'Unknown duration';

  if (duration) {
    const mins = Math.floor((duration % 3600) / 60);
    const secs = Math.floor((duration % 3600) % 60);

    durationStr = `${secs} second${conditionalS(secs)}`;
    if (mins)
      durationStr = `${mins} minute${conditionalS(mins)} and ${durationStr}`;
  }

  return (
    <div style={{position: 'relative'}}>
      <video
        style={{width: '100%', height: '88vh'}}
        {...rest}
        poster={asset?.poster?.url}
        src={asset?.preview?.url}
        onBack={() => {}}
        autoPlay
        loop
        objectFit="contain"
        data-testid="viewer-video"
      />
      <div
        style={{
          opacity: 0.1,
          backgroundImage: `url(${watermarkSrc})`,
          backgroundRepeat: 'repeat space',
          backgroundSize: '256px 256px',
          position: 'absolute',
          inset: 0,
        }}
      />
      <div style={style.previewTextContainer}>
        <Text style={style.previewText}>
          THIS IS A VIDEO PREVIEW. THE FULL VIDEO DURATION IS: {durationStr}
        </Text>
      </div>
    </div>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  close: {
    position: 'absolute',
    padding: 10,
    right: 0,
    top: 0,
    pointerEvents: 'auto',
  },
  controls: {
    pointerEvents: 'none',
    position: 'absolute',
    flex: 1,
    width: '100%',
    height: '100%',
    alignItems: 'center',
  },
  counter: {
    fontSize: 28,
    fontFamily: 'NotoSans_Bold',
    color: theme.LIGHT,
  },
  imageViewer: {
    pointerEvents: 'auto',
    justifyContent: 'center',
  },
  leftArrow: {
    position: 'absolute',
    top: '45%',
    left: 10,
    pointerEvents: 'auto',
  },
  previewText: {
    textAlign: 'center',
    fontSize: 22,
    fontFamily: 'NotoSans_Bold',
    letterSpacing: 6,
    color: theme.LIGHT,
    textShadowColor: theme.DARK,
    textShadowOffset: {width: 2, height: 2},
    textShadowRadius: 0.1,
    backgroundColor: 'rgba(0, 0, 0, .5)',
    padding: '4px',
    borderRadius: '4px',
  },
  previewTextContainer: {
    position: 'absolute',
    inset: 0,
    bottom: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  rightArrow: {
    position: 'absolute',
    top: '45%',
    right: 10,
    pointerEvents: 'auto',
  },
});
