import {node, any, oneOf, object, bool, number, string} from 'prop-types';
import React, {forwardRef, useState} from 'react';
import {
  TouchableOpacity,
  ActivityIndicator,
  Platform,
  Text,
  View,
} from 'react-native';

import FaIcon from './FaIcon';
import {useTheme} from '../context/ThemeContext';
import {PLATFORMS, STATUS} from '../utils/common/constants';
import {kebab} from '../utils/common/funcs';

const EtherButton = forwardRef(
  (
    {
      children,
      style: overrides,
      status = STATUS.IDLE,
      spinnerProps,
      disabled,
      basic,
      text,
      textColor,
      textStyle,
      icon,
      iconSize,
      iconColor, //takes priority over iconStyle's color, if supplied.
      iconStyle,
      testID,
      ...rest
    },
    ref,
  ) => {
    const {style, values} = useTheme(getThemedStyles);
    const [mouseHover, setMouseHover] = useState(null);

    const statusStyle = disabled ? style[STATUS.BUSY] : style[status];
    const hoverStyle = mouseHover ? style.brighten : {};
    const finalButtonStyle = basic
      ? statusStyle
      : {...style.buttonBase, ...hoverStyle, ...statusStyle};

    const textColorStyles = {
      dark: style.darkText,
      light: style.lightText,
      blurple: style.blurpleText,
    };

    const finalTextcolor = textColorStyles[textColor] || style.lightText;

    const content = (
      <>
        {status === STATUS.BUSY ? (
          <ActivityIndicator
            color={values.FIRST}
            size={24}
            style={style.spinnerStyles}
            {...spinnerProps}
          />
        ) : null}
        {icon && text ? (
          <View style={style.iconWithTextContainer}>
            <FaIcon
              icon={icon}
              size={iconSize || 20}
              color={
                iconColor || (iconStyle && iconStyle.color) || values.SECOND
              }
              style={[iconStyle || style.icon, {color: iconColor}]}
            />
            <Text style={textStyle || [style.text, finalTextcolor]}>
              {text}
            </Text>
          </View>
        ) : null}
        {icon && !text ? (
          <View style={style.iconOnlyContainer}>
            <FaIcon
              icon={icon}
              size={iconSize || 20}
              color={
                iconColor || (iconStyle && iconStyle.color) || values.SECOND
              }
              style={[iconStyle || style.icon, {color: iconColor}]}
            />
          </View>
        ) : null}
        {!icon && text ? (
          <Text style={textStyle || [style.text, finalTextcolor]}>{text}</Text>
        ) : null}
        {!icon && !text ? children : null}
      </>
    );

    return (
      <TouchableOpacity
        disabled={status === STATUS.BUSY || disabled}
        pointerEvents={status === STATUS.BUSY ? 'none' : 'auto'}
        onMouseOver={() => setMouseHover(true)}
        onMouseLeave={() => setMouseHover(false)}
        activeOpacity={status === STATUS.INVALID ? 1 : 0.2}
        style={[finalButtonStyle, overrides || {}]}
        ref={ref}
        testID={
          testID ||
          `button-${typeof text === 'string' ? kebab(text) : 'no-name'}`
        }
        {...rest}
      >
        {content}
      </TouchableOpacity>
    );
  },
);

EtherButton.displayName = 'EtherButton';

export const TextColor = {
  LIGHT: 'light',
  DARK: 'dark',
  BLURPLE: 'blurple',
};

EtherButton.propTypes = {
  children: node,
  style: any,
  status: oneOf(Object.values(STATUS)),
  spinnerProps: object,
  disabled: bool,
  basic: bool,
  text: node,
  textColor: oneOf(Object.values(TextColor)),
  textStyle: any,
  icon: any,
  iconSize: number,
  iconColor: string,
  iconStyle: any,
};

const getThemedStyles = (theme, fontSize) => ({
  blurpleText: {
    color: theme.FIRST,
  },
  brighten: {
    filter: 'brightness(1.05)',
  },
  buttonBase: {
    backgroundColor: theme.SECOND,
    borderColor: theme.FIRST,
    borderWidth: 2,
    height: 45,
    paddingHorizontal: 15,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  darkText: {
    color: theme.DARK,
  },
  icon: {
    color: theme.LIGHT,
  },
  iconOnlyContainer: {
    flex: 1,
    alignItems: 'center',
  },
  iconWithTextContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginHorizontal: 5,
  },
  lightText: {
    color: theme.LIGHT,
  },
  spinnerStyles: {
    position: 'absolute',
    inset: 0,
    zIndex: 2,
    opacity: 0.8,
  },
  text: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.body,
  },
  [STATUS.BUSY]: {
    backgroundColor: theme.DISABLED,
    borderColor: theme.FIRST,
    cursor: Platform.OS === PLATFORMS.web ? 'not-allowed' : null,
  },
  [STATUS.IDLE]: {},
  [STATUS.INVALID]: {
    backgroundColor: theme.RED,
    borderColor: theme.RED,
    cursor: Platform.OS === PLATFORMS.web ? 'not-allowed' : null,
  },
  [STATUS.VALID]: {
    backgroundColor: theme.GREEN,
    borderColor: theme.FIRST,
  },
});

export default EtherButton;
