import {useNavigation} from '@react-navigation/native';
import React from 'react';
import {Text} from 'react-native';

import EtherButton from './EtherButton';
import EtherMediaFullLogo from './EtherMediaLogoSVG';
import {useTheme} from '../context/ThemeContext';

export default function PoweredBy() {
  const {style} = useTheme(getThemedStyles);
  const {navigate} = useNavigation();

  return (
    <EtherButton
      style={style.container}
      basic
      onPress={() => navigate('Home', {})}
    >
      <Text style={style.text}>powered by</Text>
      <EtherMediaFullLogo />
    </EtherButton>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  container: {
    flex: 1,
    padding: 20,
    alignItems: 'center',
    justifyContent: 'center',
    maxHeight: 180,
  },
  image: {
    width: 180,
    height: 80,
  },
  text: {
    fontSize: fontSize.legal,
    fontFamily: 'NotoSans_Bold',
    color: theme.DARK,
    letterSpacing: 5,
    marginBottom: 10,
  },
});
