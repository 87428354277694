import {
  faShoppingCart,
  faCheck,
  faCartPlus,
  faVideo,
} from '@fortawesome/free-solid-svg-icons';
import {func, number, string, oneOf} from 'prop-types';
import React from 'react';
import {View, Pressable, Image, Text} from 'react-native';

import FaIcon from './FaIcon';
import {useTheme} from '../context/ThemeContext';
import {SELECT} from '../utils/common/constants';
import {secondsToTimestamp} from '../utils/common/funcs';

function CartCheck() {
  const {style} = useTheme(getThemedStyles);
  return (
    <View>
      <FaIcon style={style.cartCheckCart} size={28} icon={faShoppingCart} />
      <FaIcon style={style.cartCheckCheck} size={12} icon={faCheck} />
    </View>
  );
}

GalleryTile.propTypes = {
  onPress: func,
  onLongPress: func,
  onSelectPress: func,
  selected: oneOf(Object.values(SELECT)),
  source: string.isRequired, // URI
  width: number.isRequired,
  height: number.isRequired,
  mime: string,
  duration: number,
};
export default function GalleryTile({
  onPress = () => {},
  onLongPress = () => {},
  onSelectPress = () => {},
  selected = SELECT.DEFAULT,
  source,
  width,
  height,
  mime,
  duration,
  style: overrides,
  ...rest
}) {
  const {style} = useTheme(getThemedStyles);

  return (
    <View style={[style.container, {width, height}]} {...rest}>
      <Pressable
        style={style.touch}
        onPress={onPress}
        //SELECTABILITY FUNCTION BE HERE
        //This was disabled for MVP. The functionality might not work completely, but we felt that leaving it here would be helpful in the future.
        //onLongPress={onLongPress}
      >
        {mime.includes('video') ? (
          <>
            <FaIcon size={100} icon={faVideo} style={style.videoIcon} />
            <Text style={style.duration}>{secondsToTimestamp(duration)}</Text>
          </>
        ) : null}
        <Image style={[style.image, {width, height}]} source={{uri: source}} />
      </Pressable>
      {selected !== SELECT.DEFAULT ? (
        <Text style={style.checkbox}>
          {selected === SELECT.SELECTABLE ? (
            <Pressable onPress={() => onSelectPress()} hitSlop={20}>
              <View style={style.cartContainerWhite}>
                <FaIcon style={style.cartIcon} size={28} icon={faCartPlus} />
              </View>
            </Pressable>
          ) : (
            <Pressable onPress={() => onSelectPress()} hitSlop={20}>
              <View style={style.cartContainerGreen}>
                <CartCheck />
              </View>
            </Pressable>
          )}
        </Text>
      ) : null}
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  cartCheckCart: {
    marginRight: 2,
    marginTop: 0,
  },
  cartCheckCheck: {
    marginLeft: 12,
    marginTop: 5,
    position: 'absolute',
    color: theme.LIGHT,
  },
  cartContainerGreen: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.GREEN,
    borderRadius: 5,
    borderWidth: 2,
    borderColor: theme.DARK,
    height: 45,
    width: 45,
  },
  cartContainerWhite: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.BGFIRST,
    borderRadius: 5,
    borderWidth: 2,
    borderColor: theme.DARK,
    height: 45,
    width: 45,
  },
  cartIcon: {
    marginRight: 2,
  },
  checkbox: {
    position: 'absolute',
    right: 5,
    top: 5,
    fontSize: 40,
  },
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 13,
    marginTop: 13,
  },
  duration: {
    color: theme.LIGHT,
    backgroundColor: 'black',
    padding: 3,
    zIndex: 99999,
    position: 'absolute',
    right: 4,
    bottom: 4,
    borderRadius: 5,
    fontSize: fontSize.body,
    fontFamily: 'NotoSans_Regular',
  },
  image: {
    borderRadius: 5,
    borderWidth: 0,
    borderColor: theme.BGFIRST,
  },
  touch: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  videoIcon: {
    position: 'absolute',
    zIndex: 9999,
    marginTop: 15,
    alignSelf: 'center',
    color: 'rgba(255, 255, 255, 0.8)',
  },
});
